define('minerva-frontend/controllers/proposal/proposal', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({
    currentUser: Ember.inject.service(),
    actions: {
      refreshModel: function refreshModel() {}
    }
  });
});