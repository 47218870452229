define('minerva-frontend/models/minerva-document', ['exports', 'ember-data', 'ember-data/attr', 'ember-moment/computeds/moment', 'ember-moment/computeds/format', 'ember-moment/computeds/locale', 'moment', 'ember-custom-actions'], function (exports, _emberData, _attr, _moment, _format, _locale, _moment2, _emberCustomActions) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.documentSchema = undefined;
  var documentSchema = exports.documentSchema = {
    //Generic CoverNote Info
    documentType: (0, _attr.default)('string'), //overwritten by inheritance
    submissionStatus: _emberData.default.attr('string', { defaultValue: 'DRAFT' }), //Controlled by us!
    state: _emberData.default.attr('string'), //server set!

    documentDateTime: (0, _attr.default)('datetime-nullable'),
    owner: _emberData.default.belongsTo('user'), //attr('string'),
    submitter: _emberData.default.belongsTo('user'), //attr('string'),
    approver: _emberData.default.belongsTo('user'), //attr('string'),

    documentNumber: (0, _attr.default)('string'), //server set
    log: (0, _attr.default)(),
    //Client Information
    comtecClientId: (0, _attr.default)('string'),
    tomInfo: (0, _attr.default)(),
    clientId: (0, _attr.default)('string'), //ADDED
    comtecSafeClientId: (0, _attr.default)('string'), //ADDED
    clientIdType: (0, _attr.default)('enum'), //DS.belongsTo('params/id-type'), //Added
    firstName: (0, _attr.default)('string'),
    lastName: (0, _attr.default)('string'),
    companyName: (0, _attr.default)('string'),
    dateOfBirth: (0, _attr.default)('date-string-serializable'),
    nationality: _emberData.default.belongsTo('country'),
    gender: (0, _attr.default)('enum'), //DS.belongsTo('params/gender'),
    occupation: _emberData.default.belongsTo('occupation'),
    street: _emberData.default.belongsTo('street'),
    streetName: (0, _attr.default)('string'), //Used only of street is not chosen
    cityCodeName: (0, _attr.default)('string'), //Used only of street is not chosen

    homeStreetNumber: (0, _attr.default)('string'),
    apartmentNumber: (0, _attr.default)('number'),
    postCode: (0, _attr.default)('string'), //Used to lookup streets. (autopopulates if you select a street and the opposite)
    landlineTelNumber: (0, _attr.default)('string'),
    mobileTelNumber: (0, _attr.default)('string'),
    faxNumber: (0, _attr.default)('string'),
    emailAddress: (0, _attr.default)('string'),

    //Cover Information
    lineOfBusiness: (0, _attr.default)('enum'), //DS.belongsTo('params/business-line'), //New
    coverType: (0, _attr.default)('enum'), //DS.belongsTo('params/cover-type'),
    startDateTime: (0, _attr.default)('date'),
    endDateTime: (0, _attr.default)('date'),
    sumInsured: (0, _attr.default)('number'),
    policyExcess: (0, _attr.default)('number'),

    //Vehicle Information
    vehicleRegistrationNumber: (0, _attr.default)('string'),
    vehicleChassisNumber: (0, _attr.default)('string'),
    vehicleMake: _emberData.default.belongsTo('minerva-make'),
    vehicleModel: (0, _attr.default)('string'),
    typeOfBody: (0, _attr.default)('enum'), //DS.belongsTo('params/body-type'),
    vehicleManufactureYear: (0, _attr.default)('number'),
    vehicleEngineCapacity: (0, _attr.default)('number'),
    vehicleEnginePower: (0, _attr.default)('number'),
    vehicleNumberOfSeats: (0, _attr.default)('number', { defaultValue: 0 }),
    trailer: (0, _attr.default)('boolean'),
    trailerNumber: (0, _attr.default)('string'),

    //Drivers
    driversType: (0, _attr.default)('enum'), //DS.belongsTo('params/driver-type'),
    ownerDriving: (0, _attr.default)('boolean', { defaultValue: false }), //Kept for backward compatibility - also shown in the covernote info
    /*
    ownerDriverLearner: attr('boolean'),
    ownerDriverLicenseDate: attr('date'),
    ownerDriverForeignLicense: attr('boolean'),
    ownerExcessAmount: attr('number'),
    */
    drivers: (0, _attr.default)(), //Array of driver objects. Could use model-fragments for this!
    specialTerms: _emberData.default.attr('string'),
    remarks: (0, _attr.default)('string'),
    files: (0, _attr.default)(),
    pdfLinks: (0, _attr.default)(), //Array of links to generated pdf documents
    documentationState: (0, _attr.default)('string'), // TODO Need to make enum?
    documentationStateLabel: Ember.computed('documentationState', function () {
      var state = this.get('documentationState');
      if (state == 'EDITING') {
        return 'Editing';
      }
      if (state == 'PENDING') {
        return 'Pending review';
      }
      if (state == 'ACCEPTED') {
        return 'Accepted';
      }
      if (state == 'REVIEWING') {
        return 'Under review';
      }
      return 'Unknown/Undefined';
    }),
    documentationLog: (0, _attr.default)(), //Array of log entries regarding documentation provided
    documentationLogLastNote: Ember.computed('documentationLog.[]', function () {
      var log = this.get('documentationLog');
      if (!log) {
        return "";
      }
      if (!log.length) {
        return "";
      }
      return log[0].notes ? log[0].notes : "";
    })
  };
  exports.default = _emberData.default.Model.extend(documentSchema, {

    isCurrentlyActive: Ember.computed('state', 'startDateTime', 'endDateTime', function () {
      var state = this.get('state');
      if (state != 'ACTIVE') {
        return false;
      }
      var now = new Date();
      return now > this.get('startDateTime') && now <= this.get('endDateTime');
    }),
    hasStarted: Ember.computed('startDateTime', 'endDateTime', function () {
      var now = new Date();
      return now > this.get('startDateTime');
    }),
    isActive: Ember.computed.equal('state', 'ACTIVE'),
    isDraft: Ember.computed.equal('state', 'DRAFT'),
    isPendingApproval: Ember.computed.equal('state', 'PENDING_APPROVAL'),
    isCancelled: Ember.computed.equal('state', 'CANCELLED'),
    isDeclined: Ember.computed.equal('state', 'DECLINED'),
    stateLabel: Ember.computed('state', function () {
      var state = this.get('state');
      if (state == 'DRAFT') {
        return 'Draft';
      }
      if (state == 'PENDING_APPROVAL') {
        return 'Pending approval';
      }
      if (state == 'ACTIVE') {
        return 'Active';
      }
      if (state == 'CANCELLED') {
        return 'Cancelled';
      }
      if (state == 'DECLINED') {
        return 'Declined';
      }
      return 'Unknown/Undefined';
    }),
    startDateTimeFormatted: (0, _format.default)((0, _locale.default)((0, _moment.default)('startDateTime'), 'moment.locale'), 'DD/MM/YYYY, HH:mm'),
    endDateTimeFormatted: (0, _format.default)((0, _locale.default)((0, _moment.default)('endDateTime'), 'moment.locale'), 'DD/MM/YYYY, HH:mm'),
    dateOfBirthFormatted: (0, _format.default)((0, _locale.default)((0, _moment.default)('dateOfBirth'), 'moment.locale'), 'DD/MM/YYYY'),
    documentDateTimeFormatted: (0, _format.default)((0, _locale.default)((0, _moment.default)('documentDateTime'), 'moment.locale'), 'DD/MM/YYYY, HH:mm'),
    documentDaysSinceCreation: Ember.computed('documentDateTime', function () {
      var b = (0, _moment2.default)(this.get('documentDateTime'));
      var a = (0, _moment2.default)();
      return a.diff(b, 'days');
    }),
    isClientCompany: Ember.computed.equal('clientIdType.id', 'COMPANY_REGISTRATION'),

    clientFullName: Ember.computed('firstName', 'lastName', 'isClientCompany', function () {
      if (Ember.get(this, 'isClientCompany')) {
        return Ember.get(this, 'companyName');
      } else {
        return Ember.get(this, 'firstName') + ' ' + Ember.get(this, 'lastName');
      }
    }),
    isEndorsement: Ember.computed('documentType', function () {
      var docType = this.get('documentType');
      if (docType == 'STANDARD_ENDORSEMENT' || docType == 'DEFINITE_ENDORSEMENT') {
        return true;
      } else {
        return false;
      }
    }),
    isProposal: Ember.computed.equal('documentType', 'PROPOSAL'),
    documentTypeLabel: Ember.computed('documentType', function () {
      var docType = this.get('documentType');
      if (docType == 'COVERNOTE') {
        return 'Covernote';
      } else if (docType == 'PROPOSAL') {
        return 'Proposal';
      } else if (docType == 'STANDARD_ENDORSEMENT') {
        return 'Standard Endorsement';
      } else if (docType == 'DEFINITE_ENDORSEMENT') {
        return 'Definite Endorsement';
      } else if (docType == 'DEFINITE_COVERNOTE') {
        return 'Definite Covernote';
      } else {
        return 'Document';
      }
    }),
    ownerID: Ember.computed('owner', function () {
      return this.belongsTo("owner").id();
    }),
    updateDocumentationState: (0, _emberCustomActions.modelAction)('updateDocumentationState', { type: 'POST' })
  });
});