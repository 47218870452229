define('minerva-frontend/routes/proposals/pending-documentation', ['exports', 'ember-simple-auth/mixins/authenticated-route-mixin'], function (exports, _authenticatedRouteMixin) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend(_authenticatedRouteMixin.default, {
    model: function model() {
      // IF agent, fetch the 'failed' ones or the editing ones
      return this.get('store').query('proposal', { page: 1, size: 10, state: 'ACTIVE', documentationState: 'EDITING' });
      // IF not, fetch the PENDING ones # Aka the ones the agent sent back for approving
    }
  });
});