define('minerva-frontend/models/user', ['exports', 'ember-data', 'ember-custom-actions', 'minerva-frontend/config/environment'], function (exports, _emberData, _emberCustomActions, _environment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberData.default.Model.extend({
    findByDisplayName: (0, _emberCustomActions.resourceAction)('search/findBySearch', { type: 'GET' }),
    findUnderwritersBySearch: (0, _emberCustomActions.resourceAction)('search/findUnderwritersBySearch', { type: 'GET' }),
    changeTracker: { auto: true },
    username: _emberData.default.attr('string'),
    password: _emberData.default.attr('string'),
    firstName: _emberData.default.attr('string'),
    lastName: _emberData.default.attr('string'),
    comtecAgentCode: _emberData.default.attr('string'),
    deactivated: _emberData.default.attr('boolean'),
    landline: _emberData.default.attr('string'),
    mobile: _emberData.default.attr('string'),
    companyName: _emberData.default.attr('string'),
    address1: _emberData.default.attr('string'),
    address2: _emberData.default.attr('string'),
    district: _emberData.default.attr('enum'),
    email: _emberData.default.attr('string'),

    maxCovernoteAmount: _emberData.default.attr('number'),
    minCovernoteAmount: _emberData.default.attr('number'),
    nationalitiesAllowed: _emberData.default.hasMany('country'), //array of countries
    minLicenseAge: _emberData.default.attr('number'),
    allowNonCypriotDrivingLicense: _emberData.default.attr('boolean'),
    maxDriverAge: _emberData.default.attr('number'),
    minDriverAge: _emberData.default.attr('number'),
    maxInsuredAge: _emberData.default.attr('number'),
    minInsuredAge: _emberData.default.attr('number'),
    allowLearner: _emberData.default.attr('boolean'),
    allowSpecialTerms: _emberData.default.attr('boolean'),
    deleted: _emberData.default.attr('boolean'),

    parentUser: _emberData.default.belongsTo('user', { inverse: null }),
    notificationAddress: _emberData.default.attr('string'),
    allowAnyDriver: _emberData.default.attr('boolean'),
    roles: _emberData.default.attr('multi-enum'), //array of roles
    allowedCovernoteLobs: _emberData.default.attr('multi-enum'), //array of lobs
    allowedProposalLobs: _emberData.default.attr('multi-enum'), //array of roles
    allowedEndorsementLobs: _emberData.default.attr('multi-enum'), //array of roles'

    migratedToNewVersion: _emberData.default.attr('boolean'),

    officeName: _emberData.default.attr('string'),

    lastLoginTimestamp: _emberData.default.attr('date', {
      defaultValue: function defaultValue() {
        return new Date();
      }
    }), //For tracking purposes
    frontendAppMajorVersion: _emberData.default.attr('string', { defaultValue: _environment.default.APP.appVersion }), //We save the user's frontend app version as he sees it for tracking purposes
    frontendAppMinorVersion: _emberData.default.attr('string', { defaultValue: _environment.default.APP.minorAppVersion }), //We save the user's frontend app version as he sees it for tracking purposes

    isAdmin: Ember.computed('roles', function () {
      var roles = Ember.get(this, 'roles');
      if (!roles) {
        return false;
      }
      return roles.filterBy('id', 'ADMIN_USER').length > 0 ? true : false;

      //return get(this,'roles').indexOf('ADMIN_USER') > -1;
    }),
    isHeadUnderwriter: Ember.computed('roles', function () {
      var roles = Ember.get(this, 'roles');
      if (!roles) {
        return false;
      }
      return roles.filterBy('id', 'HEAD_UNDERWRITER').length > 0 ? true : false;

      //return get(this,'roles').indexOf('ADMIN_USER') > -1;
    }),
    isAgent: Ember.computed('roles', function () {
      var roles = Ember.get(this, 'roles');
      if (!roles) {
        return false;
      }
      return roles.filterBy('id', 'AGENT').length > 0 ? true : false;
      //return get(this,'roles').indexOf('AGENT') > -1;
    }),
    isUnderwriter: Ember.computed('roles', function () {
      var roles = Ember.get(this, 'roles');
      if (!roles) {
        return false;
      }
      return (roles.filterBy('id', 'UNDERWRITER').length > 0 ? true : false) || (roles.filterBy('id', 'HEAD_UNDERWRITER').length > 0 ? true : false);
      //return get(this,'roles').indexOf('HEAD_UNDERWRITER') > -1;
    }),
    isOfficeHead: Ember.computed('roles', function () {
      var roles = Ember.get(this, 'roles');
      if (!roles) {
        return false;
      }
      return roles.filterBy('id', 'OFFICE_HEAD').length > 0 ? true : false;
      //return get(this,'roles').indexOf('HEAD_UNDERWRITER') > -1;
    }),
    isFiler: Ember.computed('roles', function () {
      var roles = Ember.get(this, 'roles');
      if (!roles) {
        return false;
      }
      return roles.filterBy('id', 'FILER').length > 0 ? true : false;
    }),
    canIssueCovernote: Ember.computed.or('isAgent', 'isHeadUnderwriter', 'isUnderwriter', 'isAdmin'),
    //canIssueCovernote: notEmpty('allowedCovernoteLobs'),
    canIssueProposal: Ember.computed.or('isAgent', 'isHeadUnderwriter', 'isUnderwriter', 'isAdmin'),
    //canIssueProposal: Should change to something else, cause they are proposals after covernotse, not like new proposals, TODO - AFter all are migrated to V2
    canIssueEndorsement: Ember.computed.alias('canIssueCovernote'),
    //canIssueEndorsement: notEmpty('allowedEndorsementLobs'), TODO - AFter all are migrated to V2

    canRequestCancellation: Ember.computed.or('isAgent', 'isHeadUnderwriter', 'isUnderwriter', 'isAdmin'),
    canUploadFiles: Ember.computed.or('isAgent', 'isHeadUnderwriter', 'isUnderwriter', 'isAdmin', 'isFiler'),
    fullName: Ember.computed('firstName', 'lastName', function () {
      return this.get('firstName') + ' ' + this.get('lastName');
    }),
    comboBoxDisplayName: Ember.computed('firstName', 'lastName', 'username', function () {
      if (this.get('lastName') && this.get('firstName')) {
        return this.get('firstName') + ' ' + this.get('lastName') + ' (' + this.get('username') + ')';
      } else if (this.get('firstName')) {
        return this.get('firstName') + ' (' + this.get('username') + ')';
      } else if (this.get('lastName')) {
        return this.get('lastName') + ' (' + this.get('username') + ')';
      } else {
        return this.get('username');
      }
    }),
    canIssueElNM: Ember.computed.notEmpty('allowedProposalLobs')
  });
});