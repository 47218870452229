define('minerva-frontend/components/helpers/file-manager/component', ['exports', 'ember-concurrency', 'minerva-frontend/config/environment'], function (exports, _emberConcurrency, _environment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    //classNames:['file-dropzone-container'],
    session: Ember.inject.service(),
    ajax: Ember.inject.service(),
    store: Ember.inject.service(),
    currentUser: Ember.inject.service(),
    // isDocumentOwner: equal('currentUser.user.comtecAgentCode', "model.owner.comtecUser"),
    isDocumentOwner: false,

    canUserReviewDocumentation: Ember.computed.or('currentUser.user.isAdmin', 'currentUser.user.isUnderwriter', 'currentUser.user.isFiler'),
    canUserUploadDocumentation: Ember.computed.alias("currentUser.user.canUploadFiles"),

    isDocumentationInEditMode: Ember.computed('model.documentationState', function () {
      return this.get('model.documentationState') == "EDITING";
    }),
    isDocumentationAccepted: Ember.computed('model.documentationState', function () {
      return this.get('model.documentationState') == "ACCEPTED";
    }),
    isDocumentationPendingReview: Ember.computed('model.documentationState', function () {
      return this.get('model.documentationState') == "PENDING";
    }),
    isDocumentationUnderReview: Ember.computed('model.documentationState', function () {
      return this.get('model.documentationState') == "REVIEWING";
    }),
    isDocumentationInProgress: Ember.computed.alias("isDocumentationInEditMode"),

    // uploader: service('file-queue'),
    // _clearUploadedFilesFromQueue(){

    // },
    // _uploadFile: task(function* (file, description) {
    //   //let document = this.get('document');
    //   //console.log("Got a document", document);
    //   /*
    //   let uploadedFile = this.get('store').createRecord('file', {
    //     document,
    //     filename: get(file, 'name'),
    //     filesize: get(file, 'size')
    //   });
    //   */

    //   try {
    //     /*
    //     file.readAsDataURL().then(function (url) {
    //       if (get(uploadedFile, 'url') == null) {
    //         set(uploadedFile, 'url', url);
    //       }
    //     });
    //     */
    //     var headers = {};
    //     this.get('session').authorize('authorizer:jwt', (headerName, headerValue) => {
    //       headers[headerName] = headerValue;
    //     });

    //     let response = yield file.upload({url: config.APP.apiUrl + '/fileservice/', headers:headers, accepts:['application/json','text/plain']});
    //     //set(uploadedFile, 'url', response.headers.Location);

    //     if (this.get('on-upload')){
    //       this.get('on-upload')(response.body, description);
    //     }

    //   } catch (e) {
    //     if (this.get('on-upload-fail')){
    //       this.get('on-upload-fail')(e);
    //     }
    //   }
    // }).maxConcurrency(3).enqueue(),
    init: function init() {
      var _this = this;

      this._super.apply(this, arguments);

      var loggedinuser = this.get('currentUser.user.comtecAgentCode');
      this.get("model.owner").then(function (user) {
        return _this.set("isDocumentOwner", user.get("comtecAgentCode") === loggedinuser);
      });
    },

    actions: {
      saveFilename: function saveFilename(response, fileDescription) {
        var _this2 = this;

        //We should append the filename to the list of files of the model
        var model = this.get('model'); //currentModel;
        if (!model.get('files')) {
          model.set('files', []);
        }
        var newFile = {
          fileId: response.fileId,
          fileName: response.fileName,
          ext: response.ext,
          description: fileDescription,
          uploadTime: response.uploadTime
        };
        model.get('files').pushObject(newFile);

        model.save().then(function () {
          _this2.get('notifications').success("File was upload successfully");
        }).catch(function (errorResponse) {
          model.rollbackAttributes();
          console.log("Error saving the upload document details", response, errorResponse);
          _this2.get('notifications').error('There was an error while trying to upload the file. ' + errorResponse.errors);
        });
      },
      showUploadFailNotification: function showUploadFailNotification(response) {
        this.get('notifications').error('There was an error while trying to upload the file. Please make sure the file size is below 10MB and try again.');
        console.log(response);
      },
      removeFile: function removeFile(fileObj) {
        var _this3 = this;

        if (confirm('Are you sure you want to remove the file ' + fileObj.description + '?')) {
          var model = this.get('model');
          this.get('model.files').removeObject(fileObj);
          model.save().then(function () {
            _this3.get('notifications').info("File was deleted successfully");
            _this3.get('on-change')();
          }).catch(function (response) {
            model.rollbackAttributes();
            _this3.get('notifications').error('There was an error while trying to delete the file. ' + response.errors);
          });
        }
      },
      submitDocumentation: function submitDocumentation() {
        var _this4 = this;

        var payload = { "state": "PENDING", "notes": "" };
        var request = this.get('model').updateDocumentationState(payload, { pushToStore: true }).then(function () {
          _this4.get('notifications').success("You have submited the documentation successfully");
          if (_this4.get('on-success')) {
            _this4.get('on-success')();
          }
          // this.set('_submittingEndorsement', false);
        }).catch(function (response) {
          //request.rollbackAttributes();
          //this.set('_submittingEndorsement', false);
          console.log(responsoe);
          _this4.get('notifications').error('There was an error while trying to submit the documentation. ' + response.errors);
        });
      },
      startDocumentationReview: function startDocumentationReview() {
        var _this5 = this;

        var payload = { "state": "REVIEWING", "notes": "" };
        var request = this.get('model').updateDocumentationState(payload, { pushToStore: true }).then(function () {
          _this5.get('notifications').info("You can now review the documentation");
          if (_this5.get('on-success')) {
            _this5.get('on-success')();
          }
          // this.set('_submittingEndorsement', false);
        }).catch(function (response) {
          //request.rollbackAttributes();
          //this.set('_submittingEndorsement', false);
          _this5.get('notifications').error('There was an error while trying to set documentation under review. ' + response.errors);
        });
      },
      acceptDocumentation: function acceptDocumentation() {
        var _this6 = this;

        if (confirm("Are you sure you want to accept the current documentation?")) {
          var model = this.get('model');
          var payload = { "state": "ACCEPTED", "notes": "" };
          var request = this.get('model').updateDocumentationState(payload, { pushToStore: true }).then(function () {
            _this6.get('notifications').success("You have accepted the documentation successfully");
            if (_this6.get('on-success')) {
              _this6.get('on-success')();
            }
            // this.set('_submittingEndorsement', false);
          }).catch(function (response) {
            //request.rollbackAttributes();
            //this.set('_submittingEndorsement', false);
            _this6.get('notifications').error('There was an error while trying to accept the documentation. ' + response.errors);
          });
        }
      },
      resetDocumentation: function resetDocumentation() {
        var _this7 = this;

        var payload = { "state": "EDITING", "notes": "" };
        var request = this.get('model').updateDocumentationState(payload, { pushToStore: true }).then(function () {
          _this7.get('notifications').info("You can now edit the documentation");
          if (_this7.get('on-success')) {
            _this7.get('on-success')();
          }
          // this.set('_submittingEndorsement', false);
        }).catch(function (response) {
          //request.rollbackAttributes();
          //this.set('_submittingEndorsement', false);
          _this7.get('notifications').error('There was an error while trying to enable edit mode for the  documentation. ' + response.errors);
        });
      },
      declineDocumentation: function declineDocumentation() {
        var _this8 = this;

        var reason = prompt("What is the reason you cannot accept the current documentation?");
        if (reason.length < 5) {
          this.get('notifications').warning('Please provide a reason in order to decline the current documentation');
          return;
        }
        var payload = { "state": "EDITING", "notes": reason };
        var request = this.get('model').updateDocumentationState(payload, { pushToStore: true }).then(function () {
          _this8.get('notifications').info("Documentation was sent back for updating");
          if (_this8.get('on-success')) {
            _this8.get('on-success')();
          }
          // this.set('_submittingEndorsement', false);
        }).catch(function (response) {
          //request.rollbackAttributes();
          //this.set('_submittingEndorsement', false);
          _this8.get('notifications').error('There was an error while trying to decline the documentation. ' + response.errors);
        });
      },
      randomAction: function randomAction() {}
    }
  });
});