define('minerva-frontend/controllers/covernote/covernote', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({
    isShowingCancellationModal: false,
    router: Ember.inject.service(),
    currentUser: Ember.inject.service(),

    actions: {
      toggleCancellationModal: function toggleCancellationModal() {
        this.toggleProperty('isShowingCancellationModal');
      },
      toggleApprovalDeclineModal: function toggleApprovalDeclineModal() {
        this.toggleProperty('isShowingApprovalDeclineModal');
      },
      toggleTransformToEndorsementModal: function toggleTransformToEndorsementModal() {
        this.toggleProperty('isShowingTransformToEndorsementModal');
      },
      cancellationRequestSubmitted: function cancellationRequestSubmitted() {
        this.get('model').reload();
        this.toggleProperty('isShowingCancellationModal');
      },
      covernoteTransformedToEndorsementSuccess: function covernoteTransformedToEndorsementSuccess() {
        this.get('model').reload();
        this.toggleProperty('isShowingTransformToEndorsementModal');
      },
      deleteDraftCovernote: function deleteDraftCovernote() {
        var _this = this;

        var model = this.get('model'); //currentModel;
        if (model.get('submissionStatus') != 'DRAFT') {
          alert("Deleting a non-draft document is not allowed");
          return;
        }
        if (confirm("Are you sure you want to delete this draft?")) {

          model.destroyRecord().then(function () {
            _this.get('router').transitionTo('covernotes.index');
          });
        }
      },
      refreshModel: function refreshModel() {}
    }
  });
});